import { useState } from 'react'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { ItemDetalheControleComissaoPedidoStyles } from './ItemDetalheControleComissaoPedido.styles'
import { formateCurrency, porcentagemNaoMultiplicada } from '../../../../utils/stringUtils'
import { customTheme } from '../../../../styles/customTheme'
import { MoedaColorida } from '../../../../components/MoedaColorida'

const classificacaoFromNum = (classificacao: number | null): string => {
  if (classificacao === 2) return 'Em Andamento'
  if (classificacao === 0) return 'Concluido'
  return 'Pendente'
}

const getStringValorRecebido = (tipo: string, valor: number): string => {
  if (tipo == 'D') return '-'
  return formateCurrency(valor)
}

const getComissaoDevida = (tipo: string, valor: number, porcentagem: number) => {
  if (tipo != 'V') return '-'

  return (
    <>
      <MoedaColorida valor={valor} />
      {'   '}
      <ItemDetalheControleComissaoPedidoStyles.TipografiaInline colorText={customTheme.colors.azul06}>
        {porcentagemNaoMultiplicada(porcentagem)}
      </ItemDetalheControleComissaoPedidoStyles.TipografiaInline>
    </>
  )
}

export function itemDetalheControleComissaoPedidoIO() {
  const [historicoAberto, setPedidoAberto] = useState(false)
  const { isCheckListSelecionado } = useConciliacaoContext()

  return {
    styles: ItemDetalheControleComissaoPedidoStyles,
    historicoAberto,
    handleAbrirHistoricoToggle: () => setPedidoAberto(!historicoAberto),
    isCheckListSelecionado,
    classificacaoFromNum,
    getStringValorRecebido,
    getComissaoDevida
  }
}

export type ItemDetalheControleComissaoPedidoIO = ReturnType<typeof itemDetalheControleComissaoPedidoIO>
